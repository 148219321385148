// @flow
import React from 'react'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { useSpring } from 'react-spring'
import baseStyles from './ScrollTo.scss'

type Props = {
  children: any,
  toId: string,
  ...StyleProps
}

const ScrollTo = ({ children, toId, styles }: Props) => {
  const [ , setY ] = useSpring(() => ({ y: 0 }))

  const scrollTo = () => {
    if (global.document) {
      const element = document.getElementById(toId)
      const top = element ? element.getBoundingClientRect().top + window.scrollY : 0
      setY({
        y: top,
        reset: true,
        from: { y: window.scrollY },
        config: {
          duration: 250
        },
        onFrame: props => {
          window.scroll(0, props.y)
        }
      })
    }
  }
  return (
    <div className={styles.root} onClick={scrollTo}>
      {children}
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ScrollTo)
